import React, { useRef, useEffect } from "react";
import Grid from "@material-ui/core/Grid";

import Pub from "./Pub";
import Slider from "./Slider";
import { usePagination } from "./usePagination";

export default function PubList({ pubs = [], classes, grid, searchRadius, lastSearchQuery}) {
  const combinedPubData = pubs.map((pub) => {
    return {
      ...pub.PubData,
      distance: pub.distance,
      pubUrl: pub.PubData.url,
    };
  });

  const ref = useRef(null);

  const itemsPerPage = 10;

  const {
    currentPage,
    currentItems,
    renderPageNumbers,
    goToNextPage,
    goToPrevPage,
    resetPagination,
  } = usePagination(combinedPubData, itemsPerPage, ref);

  useEffect(() => {
    if (pubs) {
      resetPagination();
    }
  }, [pubs]);

  const totalItems = combinedPubData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const rangeCeil =
    totalPages === currentPage
      ? itemsPerPage * (currentPage > 1 ? currentPage - 1 : currentPage) +
          currentItems.length >
        itemsPerPage
        ? totalItems
        : itemsPerPage * (currentPage > 1 ? currentPage - 1 : currentPage) +
          currentItems.length
      : itemsPerPage * (currentPage > 1 ? currentPage : currentPage);
  const rangeFloor = (currentPage - 1) * itemsPerPage + 1;

  if (grid || combinedPubData.length < 4) {
    return (
      <div ref={ref}>
        <p className="showing">
          Showing:{" "}
          {rangeFloor === rangeCeil
            ? "All"
            : `${rangeFloor}-${rangeCeil} of ${totalItems}`}
          {lastSearchQuery && ` pubs matching "${lastSearchQuery}"`}
          {searchRadius && lastSearchQuery && ` or within ${searchRadius} miles of ${lastSearchQuery}`}
        </p>
        <Grid
          container
          style={{ width: "calc(100% + 20px)", margin: "0 -10px" }}
        >
          {currentItems.map((pub) => {
            return (
              <Grid key={pub.id} item xs={12} sm={12} md={12}>
                <Pub
                  classes={classes}
                  pub={pub}
                  featuredImage={pub.url_featured_image}
                  key={pub.id}
                />
              </Grid>
            );
          })}
          {totalPages > 1 && (
            <div className={classes.pagination}>
              <button onClick={goToPrevPage}>Prev</button>
              <ul>{renderPageNumbers()}</ul>
              <button onClick={goToNextPage}>Next</button>
            </div>
          )}
        </Grid>
      </div>
    );
  }

  return (
    <Slider classes={classes}>
      {combinedPubData.map((pub) => {
        return (
          <Pub
            key={pub.id}
            classes={classes}
            pub={pub}
            featuredImage={pub.url_featured_image}
          />
        );
      })}
    </Slider>
  );
}
